@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Montserrat:wght@200;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  @apply font-reg;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: url("../public/r2_design/saca2.png"), linear-gradient(0deg, #161D30 0%, #18243A 51%, #18243A 100%);
  background-size: contain;
  background-color: #191f36;
  background-position: center 200px;
  background-repeat: no-repeat;
}

// homepage

.hero-section {
  @apply w-full h-[50rem];
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  position: relative;
  & > .gradient-overlay {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background: linear-gradient(to right, black 40%, transparent);
    background: linear-gradient(to bottom right, black 20%, transparent);
    z-index: 2;
  }
  & > .html-overlay {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
}

.head-text {
  @apply font-digi xs:text-[1rem] md:text-[2rem] p-2 m-2 pl-1 ml-1;
}

.base-text {
  @apply font-mon xs:text-[0.8rem] md:text-[1.2rem] p-1 m-1 font-thin;
}

.mid-head-text {
  @extend .base-text;
  @apply xs:text-[0.9rem] md:text-[1.3rem] font-reg;
  color: white;
}

.pre-base-text {
  @extend .base-text;
  @apply whitespace-pre-wrap;
  display: block;
}

.home-container {
  @apply mx-auto px-2;
  max-width: 70rem;
  width: 98vw;
}
.home-section {
  @apply mt-[2rem] mb-[5rem];
  display: block;
}

.home-footer {
  @apply h-[5rem] bg-gradient-to-b to-reg from-dark;
}

.grad-card {
  @apply rounded-lg p-4 bg-gradient-to-t from-r2dark/20 to-acc4/30;
  .card-head {
    @apply my-2 font-digi;
  }
  .card-text {
    @apply my-1 font-thin;
  }
}

.fr-cc {
  @apply flex flex-row justify-center items-center;
}
.fr-sc {
  @apply flex flex-row justify-start items-center;
}
.fr-cs {
  @apply flex flex-row justify-center items-start;
}
.fr-ss {
  @apply flex flex-row justify-start items-start;
}
.fc-cc {
  @apply flex flex-col justify-center items-center;
}
.fc-sc {
  @apply flex flex-col justify-start items-center;
}
.fc-cs {
  @apply flex flex-col justify-center items-start;
}
.fc-ss {
  @apply flex flex-col justify-start items-start;
}

.page-wrapper {
  overflow: auto;
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
}

.h-page {
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  overflow: auto;
}

.page {
  @apply mx-auto xs:w-[96vw] md:w-[92vw] lg:w-[80rem] p-4 overflow-hidden;
  height: 100%;
  max-height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(lighten(#202934, 3), 0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(#06dec3, 1);
}

@layer components {
  .inp-container {
    @apply p-[2px] text-white inline-block rounded-md border border-acc0/50;
    transition: 0.2s all;
    .inp-label {
      @apply p-1;
    }
    &:focus-within {
      @apply border-2 border-acc0;
      .inp-label {
        @apply text-acc0;
      }
    }
    input {
      @apply outline-none bg-transparent p-1 font-mono text-teal-200;
      &:focus {
        @apply outline-none text-white;
        border: none;
      }
    }
  }
}

.img-obey-cont {
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.img-btn-grayed {
  @apply cursor-pointer rounded-lg transition duration-100 py-4 px-6 border-2 border-acc0 text-acc0 mx-2;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  &:hover {
    background-color: transparent;
    filter: none;
  }
  &.exit {
    @apply border border-red-400 text-red-400;
    filter: none;
  }
}

.racetrack-container {
  // height: 500px;
  // min-height: 450px;
  min-width: 100%;
  overflow: hidden;
  // overflow: visible;
  position: relative;
  z-index: 2;
  .racetrack {
    position: absolute;
    transition: all 1s;
    width: 100%;
    // height: 10450px;
    // background-size: auto 10450px;
    // background-image: url(/assets/4c830a5….svg);
    // margin-bottom: -1088.4px;
  }
}

.page-center-image {
  @apply rounded-md overflow-hidden;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  & > img {
    width: inherit;
    height: inherit;
  }
}

//mint page
.mint-box-container {
  position: relative;
  background: linear-gradient(#06dec320 0%, rgba(100, 100, 100, 0) 100%);
  border-radius: 10px;
  margin-top: 133px;
  padding: 0px 30px 33px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  width: 100%;
  max-width: 391px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
.animate-fade-in {
  animation: fade-in 0.5s ease-out; // Adjust the animation duration and easing as needed
}

.resp-text--3 {
  @apply xs:text-[0.4rem] lg:text-[0.7rem];
  opacity: 1;
}
.resp-text--2 {
  @apply xs:text-[0.45rem] lg:text-[0.8rem];
  opacity: 1;
}
.resp-text--1 {
  @apply xs:text-[0.5rem] lg:text-[0.9rem];
  opacity: 1;
}
.resp-text-0 {
  @apply xs:text-[0.55rem] lg:text-[1rem];
  opacity: 1;
}
.resp-text-1 {
  @apply xs:text-[0.6rem] lg:text-[1.2rem];
  opacity: 1;
}
.resp-text-2 {
  @apply xs:text-[0.7rem] lg:text-[1.5rem];
  opacity: 1;
}
.resp-text-3 {
  @apply xs:text-[0.8rem] lg:text-[2rem];
  opacity: 1;
}
.resp-p-1 {
  @apply xs:p-0.5 lg:p-1;
  opacity: 1;
}
.resp-p-2 {
  @apply xs:p-1 lg:p-2;
  opacity: 1;
}
.resp-p-3 {
  @apply xs:p-1.5 lg:p-3;
  opacity: 1;
}
.resp-p-4 {
  @apply xs:p-2 lg:p-4;
  opacity: 1;
}
.resp-px-1 {
  @apply xs:px-0.5 lg:px-1;
  opacity: 1;
}
.resp-px-2 {
  @apply xs:px-1 lg:px-2;
  opacity: 1;
}
.resp-px-3 {
  @apply xs:px-1.5 lg:px-3;
  opacity: 1;
}
.resp-px-4 {
  @apply xs:px-2 lg:px-4;
  opacity: 1;
}
.resp-py-1 {
  @apply xs:py-0.5 lg:py-1;
  opacity: 1;
}
.resp-py-2 {
  @apply xs:py-1 lg:py-2;
  opacity: 1;
}
.resp-py-3 {
  @apply xs:py-1.5 lg:py-3;
  opacity: 1;
}
.resp-py-4 {
  @apply xs:py-2 lg:py-4;
  opacity: 1;
}
.resp-m-1 {
  @apply xs:m-0.5 lg:m-1;
  opacity: 1;
}
.resp-m-2 {
  @apply xs:m-1 lg:m-2;
  opacity: 1;
}
.resp-m-3 {
  @apply xs:m-1.5 lg:m-3;
  opacity: 1;
}
.resp-m-4 {
  @apply xs:m-2 lg:m-4;
  opacity: 1;
}
.resp-mx-1 {
  @apply xs:mx-0.5 lg:mx-1;
  opacity: 1;
}
.resp-mx-2 {
  @apply xs:mx-1 lg:mx-2;
  opacity: 1;
}
.resp-mx-3 {
  @apply xs:mx-1.5 lg:mx-3;
  opacity: 1;
}
.resp-mx-4 {
  @apply xs:mx-2 lg:mx-4;
  opacity: 1;
}
.resp-my-1 {
  @apply xs:my-0.5 lg:my-1;
  opacity: 1;
}
.resp-my-2 {
  @apply xs:my-1 lg:my-2;
  opacity: 1;
}
.resp-my-3 {
  @apply xs:my-1.5 lg:my-3;
  opacity: 1;
}
.resp-my-4 {
  @apply xs:my-2 lg:my-4;
  opacity: 1;
}
.resp-gap-1 {
  @apply xs:gap-0.5 lg:gap-1;
  opacity: 1;
}
.resp-gap-2 {
  @apply xs:gap-1 lg:gap-2;
  opacity: 1;
}
.resp-gap-3 {
  @apply xs:gap-1.5 lg:gap-3;
  opacity: 1;
}
.resp-gap-4 {
  @apply xs:gap-2 lg:gap-4;
  opacity: 1;
}

tr.tdrow {
  td {
    @apply border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[5rem];
    foo: bar;
  }
}

tr.thintdrow {
  td {
    @apply border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[2rem];
    foo: bar;
  }
}

tr.thintdrowp4 {
  td {
    @apply border-b border-slate-600 resp-px-4 resp-py-2 text-left xs:min-w-[1rem] lg:min-w-[2rem];
    foo: bar;
  }
}

.lazyload {
  visibility: hidden;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-anim {
  animation: spin 1s infinite;
}

.card-grad-border {
  @apply border-2 border-acc1;
}

.card-grad-border2 {
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #09D3FF 0%, #755AE4 100%);
  border-image-slice: 1;
  border-radius: 0.5rem;
}

.card-grad-border3{
  position: relative;
  background: black;
  border-radius: 0.375rem; /* Equivalent to Tailwind's .rounded-md */
}

.card-grad-border3::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(to right, #09D3FF 0%, #755AE4 100%);
  z-index: -1;
  border-radius: inherit;
}

.card-grad-border4 {
  border: 15px solid transparent;
  border-image: url("../public/r2_design/grad-border.png") stretch 40;
  border-image-outset: -2px -2px -2px -2px;
}

.lbskew-card {
  width: 100%;
  height: 70px;
  margin: 0 0 0 -30px;
  transform: skew(20deg) rotate(180deg);
  background: #00000030;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

.inner {
  width: 100%;
  background: url(http://placekitten.com/301/301);
  position: absolute;
  top: 0px;
  left: -30px;
  transform: skew(-20deg) rotate(180deg);
  border-radius: 10px;
 }
}

.card-grad{
  background-image: url("../public/r2_design/grad-card.png");
  background-size: 100% 100%;
  background-position: center;
}

.card-basic2-bg{
  background-color: rgba(59, 98, 169, 0.3618);
}
.card-basic-bg{
  @apply bg-r2lig/20;
  backdrop-filter: blur(10px);
  a: b;
}

.card-dark-bg{
  @apply bg-r2dark/60;
  a: b;
}

.dark-round-btn-basic-bg{
  @apply bg-r2dark/60;
  a: b;
}

.r2card-bottom-btn{
  @apply bg-r2lig/20 resp-px-2  card-grad-border4 rounded-xl;
  a: b;
}

.backdrop-overlay{
  @apply bg-r2lig/10;
  backdrop-filter: blur(15px);
  a: b;
}

.shepherd-cust-text{
  @apply resp-text-0;
  a: b;
  // font-size: 10px;

  .shepherd-title{
    @apply resp-text-1;
    a: b;
  }

  .shepherd-text{
    @apply resp-text-0;
    a: b;
  }
}

@keyframes prizebox-backshine-scale-keys {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes prizebox-backshine-rotate-keys {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

.prizebox-backshine-anim{
  transition: all 1s;
 animation: prizebox-backshine-scale-keys 1s infinite alternate,
             prizebox-backshine-rotate-keys 10s infinite linear ;
}

@keyframes prizebox-main-fidget1-keys {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0.5rem);
  }
}
@keyframes prizebox-main-fidget2-keys {
  0% {
    scale: 1;
    transform: translate(0.25rem, 0);
  }
  50% {
    scale: 1.05;
    transform: translate(-0.25rem, 0.5rem);
  }
  100% {
    scale: 1;
    transform: translate(-0.5rem, -0.25rem);
  }
}

.prizebox-main-hover {
  transition: all 0.4s;
  animation: prizebox-main-fidget1-keys 0.6s infinite alternate,
      prizebox-main-fidget2-keys 0.8s infinite alternate;
  &:hover{
    scale: 1.1;
    animation: prizebox-main-fidget1-keys 0.68s infinite alternate,
      prizebox-main-fidget2-keys 0.3s infinite alternate;
  }
}



@keyframes prizebox-main-revealing-keys {
  0% {
    scale: 1;
    opacity: 1;
  }
  100% {
    scale: 5;
    opacity: 0;
    pointer-events: none;
  }
}

.prizebox-main-revealing {
  transition: all 0.5s;
  animation: prizebox-main-revealing-keys 1s forwards ease-in;
}

@keyframes prizebox-prize-reveal-keys {
  0% {
    opacity: 0;
    scale: 0.8;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

.prizebox-prize-revealing{
  transition: all 0.5s;
  opacity: 0;
  animation: prizebox-prize-reveal-keys 1s forwards ease-in;
  animation-delay: 0.8s;
}


@keyframes prizebox-confetti-keys {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.prizebox-confetti{
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  animation: prizebox-confetti-keys 1s forwards ease-in;
  animation-delay: 1s;
}
